require('./bootstrap');
require('./jquery');
require('./scripts');

import { createApp } from 'vue';
import { i18nVue } from 'laravel-vue-i18n'
import { axios } from 'axios'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import Validentity from './components/Validentity.vue';



const app = createApp({
    components: {
        Validentity
    }
}).use(i18nVue, {
    // lang: 'en-GB',
    resolve: async lang => import(`/lang/${lang}.json`),
    fallbackLang: '../lang/en_GB.json',
});

// app.config.globalProperties.emitter = emitter;

app.mount('#validentity');