function noLetter() {
  $('.noletter').slideDown();
}

function faq(e) {
  if($(e.currentTarget).hasClass('opened')) {
    $('.faq').removeClass('opened');
  } else {
    $('.faq').removeClass('opened');
    $(e.currentTarget).addClass('opened');
  }
}

function navigateHome(url = "/") {
  if(!window.finished) {
  var confirmAnswer = confirm('Are you sure you want to leave this process? Your progress will not be saved.');
  if(confirmAnswer == true) {
    window.document.location = url;
  } else {
    return false;
  }
} else {
  window.document.location = url;
}
}

$(function() {
    $('#ni').keyup(function() {
        this.value = this.value.toLocaleUpperCase();
    });
});

function countryChange() {
  $.ajax({
    method: "GET",
    cache: false,
    data: {
      countryCode: $('#country').val()
    },
    url: "/getCountry",
    beforeSend: function( xhr ) {
      xhr.overrideMimeType( "text/plain; charset=x-user-defined" );
    }
  })
    .done(function( data ) {
      if(data.length > 0) {
        $('#phonePrefix, #mobilePrefix').html('+' + data);
        if($('#mobileCountry').val()) {
          $('#mobileCountry').val($('#country').val());
        }
      }
    });
}

function mobileCountryChange() {
  $.ajax({
    method: "GET",
    cache: false,
    data: {
      countryCode: $('#mobileCountry').val()
    },
    url: "/getCountry",
    beforeSend: function( xhr ) {
      xhr.overrideMimeType( "text/plain; charset=x-user-defined" );
    }
  })
    .done(function( data ) {
      if(data.length > 0) {
        $('#mobilePrefix').html('+' + data);
      }
    });
}

function part2() {
  $('#part1').hide();
  $('#part2').fadeIn();
  countryChange();
}

$( document ).ready(function() {
  $('#languagePicker').change(function() {
    $('#languageForm').submit();
  });
});

function clearColourScheme() {
  const element = document.body;
  if(element.classList.contains("dark")) {
    element.classList.remove("dark");
  }
  if(element.classList.contains("light")) {
    element.classList.remove("light");
  }
}

function clearFontSize() {
  const element = document.body;
  if(element.classList.contains("normal")) {
    element.classList.remove("normal");
  }
  if(element.classList.contains("small")) {
    element.classList.remove("small");
  }
  if(element.classList.contains("large")) {
    element.classList.remove("large");
  }
}


function setColourScheme() {
  clearColourScheme();
  if(!localStorage.getItem("theme")) {
    if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
      localStorage.setItem("theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
    }
    // set it
    document.body.classList.add(localStorage.getItem("theme"));
  } else {
    document.body.classList.add(localStorage.getItem("theme"));
  }
}

function toggleMode() {
  if(document.body.classList.contains("dark")) {
    localStorage.setItem("theme", "light");
  } else {
    localStorage.setItem("theme", "dark");
  }
  setColourScheme();
}

function changeFontSize(size) {
  clearFontSize();
  if(!localStorage.getItem("fontSize") || !size) {
      localStorage.setItem("fontSize", "normal");
    // set it
    document.body.classList.add(localStorage.getItem("fontSize"));
  } else {
    localStorage.setItem("fontSize", size);
    document.body.classList.add(localStorage.getItem("fontSize"));
  }
}

function setFontSize() {
  if(!localStorage.getItem("fontSize")) {
    changeFontSize('normal');
  } else {
    changeFontSize(localStorage.getItem("fontSize"));
  }
}

function setDefaults() {
  setFontSize();
  setColourScheme();
}

// run functions on page load
setDefaults();

function checkDocUpload() {
  var fileInput = $('#fileUpload').val();
  if(fileInput.length > 0) {
    $('#documentUploadButton').fadeIn();
  }
}

function uploadDocument() {
  $('.loading').fadeIn();
  $('#documentUploadButton').attr("disabled", "disabled");
  setTimeout(function() {
    window.location = '/success';
  }, 2000);
  
}